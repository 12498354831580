import { AutomationItem } from "@src/components/sidebars/shared/constants";
import { UserSelectionInput } from "@src/types.generated";
import { useMemo } from "react";

const useGetUserSelectionInputFromAutomationItem = (
  automationItem?: AutomationItem,
): UserSelectionInput | undefined => {
  return useMemo(() => {
    if (automationItem) {
      return {
        allLocations: automationItem.automation.userSelection.allLocations,
        allTrainees: automationItem.automation.userSelection.allTrainees,
        allManagers: automationItem.automation.userSelection.allManagers,
        allAdmins: automationItem.automation.userSelection.allAdmins,
        userIds: automationItem.automation.userSelection.userMemberships.map(
          (x) => x.user.id,
        ),
        roleIds: automationItem.automation.userSelection.roleMemberships.map(
          (x) => x.role.id,
        ),
        locationIds:
          automationItem.automation.userSelection.locationMemberships.map(
            (x) => x.location.id,
          ) || [],
        locationGroupIds:
          automationItem.automation.userSelection.locationGroupMemberships.map(
            (x) => x.locationGroup.id,
          ) || [],
        roleGroupIds:
          automationItem.automation.userSelection.roleGroupMemberships.map(
            (x) => x.roleGroup.id,
          ) || [],
      };
    } else {
      return undefined;
    }
  }, [automationItem]);
};

export default useGetUserSelectionInputFromAutomationItem;
