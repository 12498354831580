import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { AutomationFragmentDoc, TranslationSetFragmentDoc, CoverImageFragmentDoc, ImageUrlsFragmentDoc, ContentQrCodeFragmentDoc, ContentTagMembershipFragmentDoc, AiGenerateModuleRequestFragmentDoc, MediaFragmentDoc, ContentSharingPermissionFragmentDoc, UserSelectionFragmentDoc, PathInstanceThinFragmentDoc } from '../../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAutomationMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
  input: Types.AutomationInput;
}>;


export type UpdateAutomationMutation = { __typename?: 'Mutation', updateAutomation: { __typename?: 'AutomationMutationResult', success: boolean, automation?: { __typename?: 'Automation', id: number, type: Types.AutomationType, enabled: boolean, autoReassign: boolean, startDatetime?: string | null, endDatetime?: string | null, newUsersOnly: boolean, userSelection: { __typename?: 'UserSelection', id: number, allLocations: boolean, allTrainees: boolean, allManagers: boolean, allAdmins: boolean, userMemberships: Array<{ __typename?: 'UserSelectionUser', id: number, user: { __typename?: 'Employee', id: number, name: string } }>, locationMemberships: Array<{ __typename?: 'UserSelectionLocation', id: number, location: { __typename?: 'Location', id: number, name: string } }>, roleMemberships: Array<{ __typename?: 'UserSelectionRole', id: number, role: { __typename?: 'Role', id: number, name: string } }>, locationGroupMemberships: Array<{ __typename?: 'UserSelectionLocationGroup', id: string, locationGroup: { __typename?: 'LocationGroup', id: string, name: string } }>, roleGroupMemberships: Array<{ __typename?: 'UserSelectionRoleGroup', id: string, roleGroup: { __typename?: 'RoleGroup', id: string, name: string } }> }, libraryItem: { __typename?: 'LibraryItem', id: string, course?: { __typename?: 'Course', id: number } | null, path?: { __typename?: 'Path', id: number } | null, trainingResource?: { __typename?: 'TrainingResource', id: string } | null } } | null } };

export type DiffAutomationChangeMutationVariables = Types.Exact<{
  input: Types.DiffAutomationRulesChangeInput;
}>;


export type DiffAutomationChangeMutation = { __typename?: 'Mutation', diffAutomationRulesChange: { __typename?: 'DiffAutomationRulesMutationResult', success: boolean, diff?: { __typename?: 'AutomationDiff', added: Array<{ __typename?: 'Employee', id: number }>, removed?: Array<{ __typename?: 'Employee', id: number }> | null } | null } };


export const UpdateAutomationDocument = gql`
    mutation UpdateAutomation($id: Int!, $input: AutomationInput!) {
  updateAutomation(id: $id, input: $input) {
    success
    automation {
      ...Automation
    }
  }
}
    ${AutomationFragmentDoc}`;
export type UpdateAutomationMutationFn = Apollo.MutationFunction<UpdateAutomationMutation, UpdateAutomationMutationVariables>;

/**
 * __useUpdateAutomationMutation__
 *
 * To run a mutation, you first call `useUpdateAutomationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutomationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutomationMutation, { data, loading, error }] = useUpdateAutomationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAutomationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAutomationMutation, UpdateAutomationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAutomationMutation, UpdateAutomationMutationVariables>(UpdateAutomationDocument, options);
      }
export type UpdateAutomationMutationHookResult = ReturnType<typeof useUpdateAutomationMutation>;
export type UpdateAutomationMutationResult = Apollo.MutationResult<UpdateAutomationMutation>;
export type UpdateAutomationMutationOptions = Apollo.BaseMutationOptions<UpdateAutomationMutation, UpdateAutomationMutationVariables>;
export const DiffAutomationChangeDocument = gql`
    mutation DiffAutomationChange($input: DiffAutomationRulesChangeInput!) {
  diffAutomationRulesChange(input: $input) {
    success
    diff {
      added {
        id
      }
      removed {
        id
      }
    }
  }
}
    `;
export type DiffAutomationChangeMutationFn = Apollo.MutationFunction<DiffAutomationChangeMutation, DiffAutomationChangeMutationVariables>;

/**
 * __useDiffAutomationChangeMutation__
 *
 * To run a mutation, you first call `useDiffAutomationChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiffAutomationChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [diffAutomationChangeMutation, { data, loading, error }] = useDiffAutomationChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDiffAutomationChangeMutation(baseOptions?: Apollo.MutationHookOptions<DiffAutomationChangeMutation, DiffAutomationChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DiffAutomationChangeMutation, DiffAutomationChangeMutationVariables>(DiffAutomationChangeDocument, options);
      }
export type DiffAutomationChangeMutationHookResult = ReturnType<typeof useDiffAutomationChangeMutation>;
export type DiffAutomationChangeMutationResult = Apollo.MutationResult<DiffAutomationChangeMutation>;
export type DiffAutomationChangeMutationOptions = Apollo.BaseMutationOptions<DiffAutomationChangeMutation, DiffAutomationChangeMutationVariables>;