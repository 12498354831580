import * as Types from '../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubsectionGradeFragment = { __typename?: 'SubsectionGrade', id: string, name: string, score: number, maxScore: number, feedback: string };

export type GradeActionItemFragment = { __typename?: 'GradeActionItem', id: string, action: string, suggestion: string, priority: Types.ActionItemPriority };

export type SectionGradeFragment = { __typename?: 'SectionGrade', id: string, name: string, score: number, maxScore: number, feedback: string, subsections: Array<{ __typename?: 'SubsectionGrade', id: string, name: string, score: number, maxScore: number, feedback: string }>, actionItems: Array<{ __typename?: 'GradeActionItem', id: string, action: string, suggestion: string, priority: Types.ActionItemPriority }> };

export type CourseVersionGradeFragment = { __typename?: 'CourseVersionGrade', id: string, totalScore: number, grade: number, feedbackMd: string, sections: Array<{ __typename?: 'SectionGrade', id: string, name: string, score: number, maxScore: number, feedback: string, subsections: Array<{ __typename?: 'SubsectionGrade', id: string, name: string, score: number, maxScore: number, feedback: string }>, actionItems: Array<{ __typename?: 'GradeActionItem', id: string, action: string, suggestion: string, priority: Types.ActionItemPriority }> }> };

export type GradeCourseVersionMutationVariables = Types.Exact<{
  input: Types.GradeCourseVersionInput;
}>;


export type GradeCourseVersionMutation = { __typename?: 'Mutation', gradeCourseVersion: { __typename?: 'GradeCourseVersionMutationResult', success: boolean, courseVersionGrade: { __typename?: 'CourseVersionGrade', id: string, totalScore: number, grade: number, feedbackMd: string, sections: Array<{ __typename?: 'SectionGrade', id: string, name: string, score: number, maxScore: number, feedback: string, subsections: Array<{ __typename?: 'SubsectionGrade', id: string, name: string, score: number, maxScore: number, feedback: string }>, actionItems: Array<{ __typename?: 'GradeActionItem', id: string, action: string, suggestion: string, priority: Types.ActionItemPriority }> }> }, error?: { __typename?: 'Error', message?: string | null } | null } };

export type CourseVersionGradeQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type CourseVersionGradeQuery = { __typename?: 'Query', AdminCourseVersion?: { __typename?: 'CourseVersion', id: number, grade?: { __typename?: 'CourseVersionGrade', id: string, totalScore: number, grade: number, feedbackMd: string, sections: Array<{ __typename?: 'SectionGrade', id: string, name: string, score: number, maxScore: number, feedback: string, subsections: Array<{ __typename?: 'SubsectionGrade', id: string, name: string, score: number, maxScore: number, feedback: string }>, actionItems: Array<{ __typename?: 'GradeActionItem', id: string, action: string, suggestion: string, priority: Types.ActionItemPriority }> }> } | null } | null };

export const SubsectionGradeFragmentDoc = gql`
    fragment SubsectionGrade on SubsectionGrade {
  id
  name
  score
  maxScore
  feedback
}
    `;
export const GradeActionItemFragmentDoc = gql`
    fragment GradeActionItem on GradeActionItem {
  id
  action
  suggestion
  priority
}
    `;
export const SectionGradeFragmentDoc = gql`
    fragment SectionGrade on SectionGrade {
  id
  name
  score
  maxScore
  feedback
  subsections {
    ...SubsectionGrade
  }
  actionItems {
    ...GradeActionItem
  }
}
    ${SubsectionGradeFragmentDoc}
${GradeActionItemFragmentDoc}`;
export const CourseVersionGradeFragmentDoc = gql`
    fragment CourseVersionGrade on CourseVersionGrade {
  id
  totalScore
  grade
  feedbackMd
  sections {
    ...SectionGrade
  }
}
    ${SectionGradeFragmentDoc}`;
export const GradeCourseVersionDocument = gql`
    mutation GradeCourseVersion($input: GradeCourseVersionInput!) {
  gradeCourseVersion(input: $input) {
    courseVersionGrade {
      ...CourseVersionGrade
    }
    success
    error {
      message
    }
  }
}
    ${CourseVersionGradeFragmentDoc}`;
export type GradeCourseVersionMutationFn = Apollo.MutationFunction<GradeCourseVersionMutation, GradeCourseVersionMutationVariables>;

/**
 * __useGradeCourseVersionMutation__
 *
 * To run a mutation, you first call `useGradeCourseVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGradeCourseVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gradeCourseVersionMutation, { data, loading, error }] = useGradeCourseVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGradeCourseVersionMutation(baseOptions?: Apollo.MutationHookOptions<GradeCourseVersionMutation, GradeCourseVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GradeCourseVersionMutation, GradeCourseVersionMutationVariables>(GradeCourseVersionDocument, options);
      }
export type GradeCourseVersionMutationHookResult = ReturnType<typeof useGradeCourseVersionMutation>;
export type GradeCourseVersionMutationResult = Apollo.MutationResult<GradeCourseVersionMutation>;
export type GradeCourseVersionMutationOptions = Apollo.BaseMutationOptions<GradeCourseVersionMutation, GradeCourseVersionMutationVariables>;
export const CourseVersionGradeDocument = gql`
    query CourseVersionGrade($id: Int!) {
  AdminCourseVersion(id: $id) {
    id
    grade {
      ...CourseVersionGrade
    }
  }
}
    ${CourseVersionGradeFragmentDoc}`;

/**
 * __useCourseVersionGradeQuery__
 *
 * To run a query within a React component, call `useCourseVersionGradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseVersionGradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseVersionGradeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseVersionGradeQuery(baseOptions: Apollo.QueryHookOptions<CourseVersionGradeQuery, CourseVersionGradeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CourseVersionGradeQuery, CourseVersionGradeQueryVariables>(CourseVersionGradeDocument, options);
      }
export function useCourseVersionGradeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CourseVersionGradeQuery, CourseVersionGradeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CourseVersionGradeQuery, CourseVersionGradeQueryVariables>(CourseVersionGradeDocument, options);
        }
export type CourseVersionGradeQueryHookResult = ReturnType<typeof useCourseVersionGradeQuery>;
export type CourseVersionGradeLazyQueryHookResult = ReturnType<typeof useCourseVersionGradeLazyQuery>;
export type CourseVersionGradeQueryResult = Apollo.QueryResult<CourseVersionGradeQuery, CourseVersionGradeQueryVariables>;