import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { shadows } from "@src/deprecatedDesignSystem/styles/shadows";
import {
  curves,
  durations,
  properties,
} from "@src/deprecatedDesignSystem/styles/transitions";
import DateTimeField, {
  DateTimeInputType,
} from "@src/deprecatedDesignSystem/components/DateTimeField";
import InlineEditButton, {
  InlineEditButtonProps,
} from "@src/deprecatedDesignSystem/components/InlineEditButton";
import RadioButton from "@src/deprecatedDesignSystem/components/RadioButton";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";
import Text from "@ui/text";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import Tooltip from "@src/deprecatedDesignSystem/components/Tooltip";
import useClickOutside from "@hooks/useClickOutside";
import { useKeystrokes } from "@hooks/useKeystrokes";
import useRefHeightAndWidth from "@hooks/useRefHeightAndWidth";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { pluralize } from "@utils/strings";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { addMinutes, formatISO9075, parseISO } from "date-fns";
import format from "date-fns/format";
import { motion, Variants } from "framer-motion";
import {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export type SelectionType = "None" | "Days" | "Date";

type Props = {
  value: string | number | undefined;
  label: string;
  onSave: (newValue: string | number | undefined) => void;
  dateTimeInputType?: DateTimeInputType;
  selectionTypes?: SelectionType[];
  daysMin?: number;
  daysMax?: number;
  minCalendarDate?: string;
  saveError?: string;
  saveLoading?: boolean;
  valueLoading?: boolean;
  style?: CSSProperties;
  styleDeclaration?: StyleDeclaration;
  inlineEditButtonProps?: Partial<InlineEditButtonProps>;
};

export const formatCalendarValueForDisplay = (
  value: string | number | undefined,
): string => {
  if (typeof value === "string") {
    const date = parseISO(value);
    const adjustedDate = addMinutes(date, -1 * date.getTimezoneOffset());
    return format(adjustedDate, "MM/dd/yyyy, hh:mm a");
  } else if (typeof value === "number") {
    return `${value} ${pluralize("day", value)}`;
  } else {
    return "None";
  }
};

const InlineEditCalendarField: FC<Props> = ({
  value,
  label,
  onSave,
  dateTimeInputType = "datetime-local",
  selectionTypes = ["None", "Days", "Date"],
  daysMin,
  daysMax,
  minCalendarDate,
  saveError,
  saveLoading = false,
  valueLoading,
  style,
  styleDeclaration,
  inlineEditButtonProps = {},
}) => {
  const dropdownMenuRef = useRef(null);
  const [ref] = useRefHeightAndWidth();
  const [isEditing, setIsEditing] = useState(false);
  const [hasEdited, setHasEdited] = useState(false);
  const [clickedSave, setClickedSave] = useState(false);
  const [newValue, setNewValue] = useState<string | number | undefined>(value);
  useEffect(() => {
    setNewValue(value);
  }, [value]);
  const [validationError, setValidationError] = useState<string | undefined>(
    undefined,
  );
  const [selectedOption, setSelectedOption] = useState<SelectionType>("None");
  const [days, setDays] = useState(0);
  const [date, setDate] = useState(new Date());
  const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);

  const onSaveClick = useCallback(() => {
    setValidationError(undefined);
    setHasEdited(false);

    if (newValue === 0) {
      setValidationError("Number of days must be greater than 0");
    } else {
      setClickedSave(true);
      onSave(newValue);
    }
  }, [onSave, newValue]);

  const keyStrokeConfig = useMemo(() => {
    return {
      Enter: (event: { preventDefault: () => void }) => {
        if (isEditing) {
          event.preventDefault();
          onSaveClick();
        }
      },
      Escape: (event: { preventDefault: () => void }) => {
        if (isEditing) {
          event.preventDefault();
          onSaveClick();
        }
      },
    };
  }, [isEditing, onSaveClick]);
  useKeystrokes(keyStrokeConfig);

  const onClickOutside = useCallback(() => {
    if (isEditing) {
      onSaveClick();
    }
  }, [isEditing, onSaveClick]);
  useClickOutside(dropdownMenuRef, onClickOutside);

  useEffect(() => {
    if (clickedSave && !saveLoading && !saveError) {
      setIsEditing(false);
    }
  }, [clickedSave, saveLoading, saveError, setIsEditing]);

  useEffect(() => {
    if (selectedOption === "Date") {
      const utcDateTime = addMinutes(date, date.getTimezoneOffset());
      setNewValue(formatISO9075(utcDateTime));
    } else if (selectedOption === "Days") {
      setNewValue(days);
    } else {
      setNewValue(undefined);
    }
  }, [selectedOption, date, days, value]);

  useEffect(() => {
    if (!initialLoadCompleted && !valueLoading) {
      setInitialLoadCompleted(true);
      setNewValue(value);
      if (typeof value === "string") {
        setDate(parseISO(value));
        setSelectedOption("Date");
      } else if (typeof value === "number") {
        setDays(value);
        setSelectedOption("Days");
      } else {
        setSelectedOption("None");
      }
    }
  }, [valueLoading, value, initialLoadCompleted]);

  return (
    <DropdownMenu.Root open={isEditing}>
      <DropdownMenu.Trigger asChild>
        <div ref={ref}>
          <InlineEditButton
            values={[formatCalendarValueForDisplay(newValue)]}
            onClick={() => setIsEditing(true)}
            loading={valueLoading}
            {...inlineEditButtonProps}
          />
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        align="start"
        sideOffset={-33}
        style={{ zIndex: 2000 }}
      >
        <div
          ref={dropdownMenuRef}
          className={css(styles.contentContainer, styleDeclaration)}
          style={{
            ...style,
          }}
        >
          <motion.div
            initial="hide"
            animate={
              (saveError || validationError) && !hasEdited && !saveLoading
                ? "show"
                : "hide"
            }
            variants={saveErrorTooltipAnimation}
            className={css(styles.saveErrorTooltipContainer)}
          >
            <Tooltip
              text={saveError || validationError || ""}
              caretPosition="Bottom"
              color={deprecatedTones.red10}
            />
          </motion.div>
          <div className={css(styles.selectedValueContainer)}>
            <Text type="P2" fontWeight="SemiBold">
              {formatCalendarValueForDisplay(newValue)}
            </Text>
            <div className={css(styles.actionsContainer)}>
              <motion.div
                initial="hide"
                animate={saveLoading ? "show" : "hide"}
                variants={spinnerContainerAnimation}
                className={css(styles.spinnerContainer)}
              >
                <Spinner size={16} color={deprecatedTones.blue9} />
              </motion.div>
            </div>
          </div>
          <div className={css(styles.labelAndOptionsContainer)}>
            <Text
              type="P3"
              fontWeight="SemiBold"
              color={deprecatedTones.gray8}
              styleDeclaration={styles.contentTitleStyles}
            >
              {label}
            </Text>
            <div className={css(styles.optionsContainer)}>
              {selectionTypes.includes("Date") ? (
                <div
                  onClick={() => {
                    if (selectedOption !== "Date") {
                      setSelectedOption("Date");
                    }
                  }}
                >
                  <div className={css(styles.optionContainer)}>
                    <RadioButton
                      labelOnly={selectionTypes.length === 1}
                      checked={selectedOption === "Date"}
                      label="Date"
                      onClick={() => {}}
                      style={{ marginRight: 16 }}
                    />
                    <DateTimeField
                      value={date}
                      type={dateTimeInputType}
                      onChange={setDate}
                      styleDeclaration={styles.dateTimeInputStyles}
                      min={minCalendarDate}
                    />
                  </div>
                </div>
              ) : null}
              {selectionTypes.includes("Days") ? (
                <div
                  onClick={() => {
                    if (selectedOption !== "Days") {
                      setSelectedOption("Days");
                    }
                  }}
                >
                  <div className={css(styles.optionContainer)}>
                    <RadioButton
                      labelOnly={selectionTypes.length === 1}
                      checked={selectedOption === "Days"}
                      label="Days"
                      onClick={() => {}}
                      style={{ marginRight: 16 }}
                    />
                    <TextField
                      inputType="number"
                      max={daysMax}
                      min={daysMin}
                      text={days.toString()}
                      onTextChange={(text) => {
                        const v = parseInt(text);
                        const newValue = Math.max(
                          1,
                          Math.min(isNaN(v) ? 1 : v, 999),
                        );
                        setDays(newValue);
                      }}
                    />
                  </div>
                </div>
              ) : null}
              {selectionTypes.includes("None") ? (
                <div
                  onClick={() => {
                    if (selectedOption !== "None") {
                      setSelectedOption("None");
                    }
                  }}
                >
                  <div className={css(styles.optionContainer)}>
                    <RadioButton
                      labelOnly={selectionTypes.length === 1}
                      checked={selectedOption === "None"}
                      label="None"
                      onClick={() => {}}
                      style={{ flex: 1 }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

const saveErrorTooltipAnimation: Variants = {
  hide: {
    opacity: 0,
    y: 4,
  },
  show: {
    opacity: 1,
    y: 0,
  },
};

const spinnerContainerAnimation: Variants = {
  show: {
    opacity: 1,
  },
  hide: {
    opacity: 0,
  },
};

const styles = StyleSheet.create({
  saveErrorTooltipContainer: {
    position: "absolute",
    top: -54,
    width: "100%",
    pointerEvents: "none",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 8,
    overflow: "hidden",
    backgroundColor: deprecatedTones.white,
    boxShadow: shadows.elevation3,
  },
  selectedValueContainer: {
    display: "flex",
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "space-between",
    height: 32,
    paddingRight: 4,
    paddingLeft: 8,
    backgroundColor: deprecatedTones.white,
    borderBottom: `1px solid ${deprecatedTones.gray5Alpha}`,
  },
  actionsContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    gap: 4,
  },
  iconStyles: {
    cursor: "pointer",
    borderRadius: 24,
  },
  closeIconStyles: {
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    backgroundColor: deprecatedTones.white,
    transitionDuration: durations.S025,
    transitionProperty: properties.backgroundColor,
    transitionTimingFunction: curves.bezier,
    ":hover": {
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
  checkIconStyles: {
    backgroundColor: deprecatedTones.blue9,
    transitionDuration: durations.S025,
    transitionProperty: properties.backgroundColor,
    transitionTimingFunction: curves.bezier,
    ":hover": {
      backgroundColor: deprecatedTones.blue10,
    },
  },
  iconContainer: {
    pointerEvents: "all",
  },
  spinnerContainer: {
    position: "absolute",
    top: 0,
    right: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 24,
    height: 24,
    pointerEvents: "none",
  },
  labelAndOptionsContainer: {
    alignSelf: "stretch",
    paddingTop: 12,
  },
  optionsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  contentTitleStyles: {
    display: "block",
    paddingRight: 4,
    paddingBottom: 4,
    paddingLeft: 8,
  },
  optionContainer: {
    display: "flex",
    alignItems: "center",
    padding: "12px 8px",
    backgroundColor: deprecatedTones.white,
    cursor: "pointer",
    transitionDuration: durations.S015,
    transitionProperty: properties.backgroundColor,
    transitionTimingFunction: curves.bezier,
    ":hover": {
      border: "none",
      outline: "none",
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
  dateTimeInputStyles: {
    flex: 1,
  },
});

export default InlineEditCalendarField;
